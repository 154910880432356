/* eslint-disable jsx-a11y/media-has-caption */
import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'gatsby';
import cn from 'clsx';
import PropTypes from 'prop-types';
import { gsap, TweenLite, Power3 } from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import axios from 'axios';

import Layout from 'components/Layout';
import Meta from 'components/Meta';
import TrustedBy from 'components/TrustedBy';
import FeatureContainer from '../components/FeatureContainer';
import { btnOnClick } from '../utils/contentProvider/function';
import TestimonialsSlider from '../components/TestimonialsSlider/TestimonialsSlider';
import SubHeader from '../components/SubHeader/SubHeader';
import NewFaq from '../components/NewFaq/NewFaq';
import Reviews from '../components/Reviews';
import ProjectUrlModal from '../components/ProjectUrlModal/ProjectUrlModal';
import FeaturesGrid from '../components/FeaturesGrid/FeaturesGrid';
import { websiteFeedbackFeatures } from '../utils/contentProvider';

import 'styles/usecases.scss';
import 'styles/bug-tracking.scss';
import {
  MixPanelButtonContext,
  MixPanelEvents,
  PostHogButtonContext,
  PostHogEvents,
} from '../constants';
import { faqDataCommon } from '../utils/contentProvider/faqDataCommon';

const WebsiteFeedbackTool = ({ location }) => {
  const [showVideoModal, setVideoModal] = useState(false);
  const [activeSection, setActiveSection] = useState(null);
  const [isFloating, setIsFloating] = useState(true);
  const [inputUrl, setInputUrl] = useState('');
  const [showProjectUrlModal, setShowProjectUrlModal] = useState(false);
  const [projectUrl, setProjectUrl] = useState('');
  const [projectData, setProjectData] = useState({});
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [focus, setFocus] = useState(false);
  const [floatingFocus, setFloatingFocus] = useState(false);

  const vidRef = useRef();
  const vidAreaRef = useRef();
  const heroContainer = useRef();
  const heroLeftCard = useRef();
  const heroRightCard = useRef();

  gsap.registerPlugin(ScrollTrigger);
  useEffect(() => {
    gsap.fromTo(
      heroLeftCard.current,
      {
        x: 0,
        y: 0,
        rotation: 0,
        scale: 1,
        opacity: 1,
        transformOrigin: 'center',
      },
      {
        duration: 0.2,
        x: 300,
        y: 400,
        rotation: 20,
        scale: 0.8,
        opacity: 0.2,
        ease: 'none',
        transformOrigin: 'center',
        scrollTrigger: {
          trigger: heroLeftCard.current,
          start: 'top 130px',
          end: 'bottom top',
          scrub: 1,
          direction: 'reverse',
          // markers: true,
        },
      },
    );

    gsap.fromTo(
      heroRightCard.current,
      {
        x: 0,
        y: 0,
        rotation: 0,
        scale: 1,
        opacity: 1,
        transformOrigin: 'center',
      },
      {
        duration: 0.2,
        x: -300,
        y: 400,
        rotation: -14,
        scale: 0.8,
        opacity: 0.2,
        ease: 'none',
        transformOrigin: 'center',
        scrollTrigger: {
          trigger: heroRightCard.current,
          start: 'top 130px',
          end: 'bottom top',
          scrub: 1,
          direction: 'reverse',
        },
      },
    );
  }, []);

  const handleScroll = () => {
    const sections = document.querySelectorAll('section');
    const currentPosition = window.scrollY + window.innerHeight / 2;
    const position = window.pageYOffset;
    const urlInput = document.querySelector('.project-url-input');

    if (position > 500) {
      urlInput.classList.remove('static-project-url');
    } else {
      urlInput.classList.add('static-project-url');
      setFloatingFocus(false);
    }

    sections.forEach((section) => {
      if (
        section.offsetTop <= currentPosition &&
        section.offsetTop + section.offsetHeight > currentPosition
      ) {
        setActiveSection(section.getAttribute('id'));
      }
    });

    if (window.scrollY > 115) {
      setIsFloating(false);
    } else {
      setIsFloating(true);
    }
  };

  const checkUrl = (url) => {
    if (!url.startsWith('http://') && !url.startsWith('https://')) {
      return `https://${url}`;
    }
    return url;
  };
  const addPageHandler = () => {
    if (inputUrl === '') {
      setError('Please enter a URL');
      return;
    }
    (async () => {
      setLoading(true);
      try {
        const response = await axios.post(
          'https://app.ruttl.com/apphighmem/anonymous-project',
          {
            url: checkUrl(inputUrl),
            action: 'create',
          },
        );

        if (response.status === 200) {
          setProjectUrl(
            `https://web.ruttl.com/share/${response.data.projectID}?page=${response.data.pageID}`,
          );
          setProjectData(response.data);
          setLoading(false);
          window.open(
            `https://web.ruttl.com/share/${response.data.projectID}?page=${response.data.pageID}`,
            '_blank',
          );
          setShowProjectUrlModal(true);
          setInputUrl('');
          setError(false);
        }
      } catch (err) {
        setLoading(false);
        setError('Something went wrong. Please enter a valid URL.');
      }
    })();
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  useEffect(() => {
    // const ZOOM_SPEED = 0.0001
    // eslint-disable-next-line no-unused-vars
    if (window.innerWidth > 767) {
      let zoom = 0.8;
      const zoomElement = vidAreaRef.current;

      document.addEventListener('scroll', () => {
        if (window.scrollY < 160) {
          zoom = 0.8 + (window.scrollY * 0.2) / 160;
          TweenLite.to(zoomElement, 0.5, {
            scale: zoom,
            ease: Power3.easeOut,
          });
        } else if (window.scrollY >= 160) {
          zoom = 1;
          TweenLite.to(zoomElement, 0.5, {
            scale: zoom,
            ease: Power3.easeOut,
          });
        }
      });
    }
  }, []);

  function toggleVideoModal() {
    setVideoModal(!showVideoModal);
  }
  useEffect(() => {
    document.body.style.overflow = showVideoModal ? 'hidden' : 'auto';
  }, [showVideoModal]);

  return (
    <Layout
      location={location}
      hideTopBar
      productPage
      hideHeader
      product="Website Feedback Tool">
      {({ toggleContactUs }) => (
        <>
          <Meta
            title="Website Feedback Tool by Ruttl"
            description="Website Feedback Tool to instantly add comments and edits to your live website, to share as feedback. Trusted by over 15,000 companies & professionals."
            url="https://ruttl.com/website-feedback-tool/"
          />
          <div
            id="demo-modal"
            className={cn(['demo-modal', { show: showVideoModal }])}>
            <button
              type="button"
              className="button-unstyled"
              onClick={toggleVideoModal}>
              <img src="/assets/img/close.svg" alt="close" title="close" />
            </button>
            {showVideoModal && (
              <iframe
                width="560"
                height="315"
                src="https://www.youtube-nocookie.com/embed/yMyZpJqWRso"
                frameBorder="0"
                title="ruttl demo"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            )}
          </div>
          <div
            className={`project-url hidden-mob  project-url-input ${
              floatingFocus ? 'focus' : 'static-project-url'
            }`}>
            <input
              placeholder="Enter website URL for review"
              value={inputUrl}
              required
              onFocus={() => setFloatingFocus(true)}
              onBlur={() => setFloatingFocus(false)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  addPageHandler();
                }
              }}
              onChange={(e) => setInputUrl(e.target.value)}></input>
            <button
              type="button"
              className="button hidden-mob"
              disabled={loading}
              id="add-page-sticky-wft"
              onClick={addPageHandler}>
              {loading ? 'Adding...' : 'Add Page'}
            </button>
          </div>

          {showProjectUrlModal && (
            <ProjectUrlModal
              toggleProjectUrlModal={() => setShowProjectUrlModal(false)}
              showProjectUrlModal={showProjectUrlModal}
              projectUrl={projectUrl}
              projectData={projectData}
            />
          )}
          <SubHeader
            productType="website-feedback-tool"
            productName="Website Feedback Tool"
            showVideoModal
            setActiveSection={setActiveSection}
            activeSection={activeSection}
          />
          <main className="styled-main">
            <section
              className="features-hero custom-padding"
              ref={heroContainer}>
              <div className="container relative">
                <div
                  className="left-floating-card floating-cards hidden-mob"
                  ref={heroLeftCard}>
                  <div
                    className={`hero-tilted-cards left-card ${
                      isFloating ? 'float-left' : ''
                    }`}>
                    <div className="card-content">
                      <span>Screenshot</span>
                      <p>Increase the size of the image</p>
                    </div>
                    <img
                      src="/assets/img/tilted-card-2.png"
                      alt="comment showing screenshot"
                    />
                  </div>
                </div>
                <div
                  className="right-floating-card floating-cards hidden-mob"
                  ref={heroRightCard}>
                  <div
                    className={`hero-tilted-cards right-card ${
                      isFloating ? 'float-right' : ''
                    }`}>
                    <div className="card-content">
                      <span className="right-card">
                        <img
                          src="/assets/img/logo/slack.svg"
                          alt="slack logo"
                        />
                        Slack
                      </span>
                      <p>Keep a note of these changes</p>
                    </div>
                    <img
                      src="/assets/img/tilted-card-1.png"
                      alt="slack message"
                    />
                  </div>
                </div>
                <div className="features-hero-head">
                  <div className="hero-heading">
                    Fastest&nbsp;
                    <h1
                      style={{
                        margin: 0,
                        display: 'inline',
                      }}>
                      website feedback tool
                    </h1>
                    ,
                    <br />
                    now&nbsp;
                    <span className="blue-text">powered by AI</span>
                  </div>
                  <p>
                    Add&nbsp;
                    <h2
                      style={{
                        display: 'inline',
                        fontWeight: 500,
                        fontSize: 'inherit',
                        margin: 0,
                        lineHeight: 'inherit',
                        opacity: 0.8,
                      }}>
                      website feedback
                    </h2>
                    &nbsp;with comments, directly on live websites, and also
                    make real-time edits integrated with generative AI. No more
                    clumsy emails or screenshots.
                  </p>
                  {/* <div className="hero-review-block">
                    <div className="review-stars">
                      <img src="/assets/img/stars.svg" alt="Star ratings" />
                      <p>Based on 100+ reviews on</p>
                    </div>

                    <div className="review-logos">
                      <img
                        src="/assets/img/logo/g2-crowd-full.png"
                        alt="G2 Crowd Logo"
                      />
                      <img
                        src="/assets/img/logo/capterra-full.png"
                        alt="Capterra Logo"
                      />
                      <img
                        src="/assets/img/logo/get-app-full.png"
                        alt="Get App Logo"
                      />
                    </div>
                  </div> */}
                  <div className={`project-url ${focus ? 'focus' : ''}`}>
                    <input
                      placeholder="Enter website URL for review"
                      value={inputUrl}
                      required
                      onFocus={() => setFocus(true)}
                      onBlur={() => setFocus(false)}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          addPageHandler();
                        }
                      }}
                      onChange={(e) => setInputUrl(e.target.value)}></input>
                    <button
                      type="button"
                      className="button hidden-mob"
                      disabled={loading}
                      id="add-page-hero-wft"
                      onClick={addPageHandler}>
                      {loading ? 'Adding...' : 'Add Page'}
                    </button>
                  </div>
                  <button
                    type="button"
                    className="button hidden-web"
                    disabled={loading}
                    onClick={addPageHandler}>
                    {loading ? 'Adding...' : 'Add Page'}
                  </button>
                  {error && (
                    <div className="error">
                      <span>{error}</span>
                    </div>
                  )}
                </div>
                {/* <div
                  className="reveal-1 button-container"
                  style={{ margin: '30px 0' }}>
                  <a href="https://web.ruttl.com" className="button">
                    Get started for free
                  </a>
                  <a
                    href="https://calendly.com/setup-demo-with-ruttl/30min"
                    target="_blank"
                    rel="noopener noreferrer"
                    type="button"
                    className="button book-demo">
                    Book a Demo
                  </a>
                </div> */}
                <div className="index-hero--preview reveal-1" ref={vidAreaRef}>
                  {/* <img src="/assets/img/topbar-white.svg" alt="window top bar" /> */}
                  <video
                    ref={vidRef}
                    autoPlay
                    muted
                    loop
                    poster="assets/img/index-hero.jpg"
                    title="ruttl demo animation"
                    playsInline
                    className="curved-border">
                    <source
                      src="https://static.ruttl.com/ruttl-website-feedback-tool-demo.webm"
                      type="video/webm"
                    />
                    <source
                      src="https://static.ruttl.com/ruttl-website-feedback-tool-demo.mp4"
                      type="video/mp4"
                    />
                  </video>
                </div>

                <button
                  type="button"
                  className="button hero-video-button"
                  id="watch-demo-wft"
                  onClick={btnOnClick({
                    mixPanelEvent: MixPanelEvents.ButtonClick,
                    posthogEvent: PostHogEvents.ButtonClick,
                    config: {
                      Context: MixPanelButtonContext.WatchTheDemo,
                    },
                    posthogConfig: {
                      Context: PostHogButtonContext.WatchTheDemo,
                    },
                    callback: () => toggleVideoModal(1),
                  })}>
                  Watch the demo
                  <svg width="20" height="20" viewBox="0 0 512 512">
                    <path
                      fill="none"
                      stroke="#fff"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="48"
                      d="M268 112l144 144-144 144M392 256H100"></path>
                  </svg>
                </button>
              </div>
              <img
                loading="lazy"
                className="index-hero--oval "
                src="/assets/img/index-hero-oval.svg"
                alt="abstract oval shape"
                title="abstract oval shape"
              />
              <TrustedBy />
            </section>

            <section className="usecases-styled-main" id="how-it-works">
              <div className="section features-main">
                <div className="container">
                  <div className="section-head">
                    <span
                      className="center collect-feedback"
                      style={{
                        maxWidth: '700px',
                      }}>
                      No more screenshots or clumsy excel sheets to&nbsp;
                      <h2>collect feedback on websites</h2>
                    </span>
                  </div>

                  <FeatureContainer>
                    <div className="feature-set-single">
                      <div className="flex flex-wrap align-items-center ">
                        <div className="feature-img">
                          <div className="feature-img-bg">
                            <img
                              loading="lazy"
                              src="/assets/img/website-feedback-comment-on-live-website.webp"
                              alt="Website Feedback Tool - Comment on live website"
                              title="Website Feedback Tool - Comment on live website"
                            />
                          </div>
                        </div>
                        <div className="feature-txt">
                          <h3 className="feature-head">
                            Comment directly on live websites
                          </h3>
                          <p>
                            Add pixel-pinned contextual comments directly on any
                            live website to share your feedback.
                          </p>
                        </div>
                      </div>
                    </div>
                  </FeatureContainer>
                  <FeatureContainer>
                    <div className="feature-set-single">
                      <div className="flex flex-wrap align-items-center ">
                        <div className="feature-img">
                          <div className="feature-img-bg">
                            <img
                              loading="lazy"
                              src="/assets/img/website-feedback-edit-content-ai.webp"
                              alt="Website Feedback Tool - Edit content with AI"
                              title="Website Feedback Tool - Edit content with AI"
                            />
                          </div>
                        </div>
                        <div className="feature-txt">
                          <h3 className="feature-head">Edit Content</h3>
                          <p>
                            Effortlessly edit and rephrase web page content in
                            real time with our AI text generator. Content
                            editing has never been easier!
                          </p>
                        </div>
                      </div>
                    </div>
                  </FeatureContainer>
                  <FeatureContainer>
                    <div className="feature-set-single">
                      <div className="flex flex-wrap align-items-center ">
                        <div className="feature-img">
                          <div className="feature-img-bg">
                            <img
                              loading="lazy"
                              src="/assets/img/website-feedback-platform-agnostic.webp"
                              alt="Website Feedback - Platform Agnostic"
                              title="Website Feedback - Platform Agnostic"
                            />
                          </div>
                        </div>
                        <div className="feature-txt">
                          <h3 className="feature-head">Platform-Agnostic</h3>
                          <p>
                            Works with everything - WordPress, Shopify, Custom
                            HTML + CSS, Webflow, Instamojo, & more!
                          </p>
                        </div>
                      </div>
                    </div>
                  </FeatureContainer>
                  <FeatureContainer>
                    <div className="feature-set-single">
                      <div className="flex flex-wrap align-items-center ">
                        <div className="feature-img">
                          <div className="feature-img-bg">
                            <img
                              loading="lazy"
                              src="/assets/img/website-feedback-guest-commenting.webp"
                              alt="Website Feedback - Guest Commenting"
                              title="Website Feedback - Guest Commenting"
                            />
                          </div>
                        </div>
                        <div className="feature-txt">
                          <h3 className="feature-head">
                            Guest Commenting for guests and clients
                          </h3>
                          <p>
                            Share the project with your clients & get comments
                            from them - all without them needing to sign-up!
                          </p>
                        </div>
                      </div>
                    </div>
                  </FeatureContainer>
                </div>
              </div>
            </section>
            <FeaturesGrid
              data={websiteFeedbackFeatures}
              productType="website-feedback-tool"
            />
            <section className="container center advanced" id="bug-tracking">
              <h3>Advanced Bug tracking tool for QA teams</h3>
              <p>
                Streamline your QA workflow — Set yourself free from the endless
                emails within your team, enable seamless collaboration and
                efficient product management.
              </p>
              <img
                loading="lazy"
                src="/assets/img/product-companies-bug-tracking.png"
                alt="Bug-tracking"
                title="Bug-tracking"
              />
              <Link to="/bug-tracking-tool" type="button" className="button">
                Learn More
              </Link>
              <a
                className="play-now-btn"
                href="https://smashbugs.ruttl.com/"
                target="_blank"
                rel="noopener noreferrer">
                Play Now
              </a>
            </section>
            <TestimonialsSlider />
            <section className="section-gap">
              <div className="container">
                <h2 className="center" style={{ marginBottom: 50 }}>
                  Explore other products
                </h2>
                <div className="flex-row justify-content-center">
                  {[
                    {
                      id: 0,
                      icon: '/assets/img/customer-feedback-icon.svg',
                      heading: 'Customer Feedback',
                      text: 'Chat with your website users in real time & resolve their issues with your team',
                      url: '/customer-feedback-tool/',
                    },
                    {
                      id: 1,
                      icon: '/assets/img/mobile-app-feedback-icon.svg',
                      heading: 'Mobile App Feedback',
                      text: 'Mark issues directly on mobile application, organise feedback using our internal workflow',
                      url: '/mobile-app-feedback/',
                    },
                  ].map(({ id, icon, heading, text, url }) => (
                    <div key={id} className="col col-3 col-md-6">
                      <Link to={url} className="icon-text-box">
                        <div className="box-icon">
                          <img src={icon} alt={`${heading} icon`} />
                        </div>
                        <div className="box-desc">
                          <h3>{heading}</h3>
                          <p>{text}</p>
                        </div>
                      </Link>
                    </div>
                  ))}
                </div>
              </div>
            </section>
            <Reviews />
            <NewFaq toggleContactUs={toggleContactUs} data={faqDataCommon} />
          </main>
        </>
      )}
    </Layout>
  );
};

WebsiteFeedbackTool.propTypes = {
  location: PropTypes.object,
};

export default WebsiteFeedbackTool;
